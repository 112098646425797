import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "./providers";
import { App } from "./app";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";


ReactDOM.render(
  <StrictMode>
    <Provider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
